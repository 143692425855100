import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { TweenMax, TimelineMax, Elastic, Power2 } from 'gsap';

// #region Styles
const AvatarImage = styled.svg`
  display: block;
  height: 100%;
  opacity: 0;
  padding: 0;
  width: 100%;
`;

const ClearPath = styled.path`
  fill: none;
`;
const Skin = styled.path`
  fill: #db9f67;
`;
const SkinHighlight = styled.path`
  fill: #eaaf7f;
`;
const SkinShadow = styled.path`
  fill: #c77b46;
`;
const DarkBrown = styled.path`
  fill: #403129;
`;
const LightBrown = styled.path`
  fill: #715647;
`;
const EyeBrow = styled.path`
  fill: #582c1b;
`;
const EyeColor = styled.path`
  fill: #703c1f;
`;
const Lips = styled.path`
  fill: #621f1c;
`;
const Hat = styled.path`
  fill: #3f5555;
`;
const HatShadow = styled.path`
  fill: #1b2828;
`;
const HatOutline = styled.path`
  fill: #0b1c1c;
`;
const Shirt = styled.path`
  fill: #c0544f;
`;
const ShirtCollar = styled.path`
  fill: #762a26;
`;
const HairOutline = styled.path`
  fill: #000;
`;
const Nostrils = styled.path`
  fill: #000;
`;
const Glasses = styled.path`
  fill: #000;
`;
const Iris = styled.path`
  fill: #000;
`;
const EyeLashes = styled.path`
  fill: #000;
`;
const White = styled.path`
  fill: #fff;
`;
const Glass = styled.path`
  fill: #eeeeffaa;
`;
const OuterCircle = styled.circle`
  fill: #324f4f;
`;
const InnerCircle = styled.circle`
  fill: #52c8ff;
`;
// #endregion

const Avatar = () => {
  const svgRef = useRef();
  const headAndBodyRef = useRef();
  const headRef = useRef();
  const eyelidsRef = useRef();
  const hairRef = useRef();

  useEffect(() => {
    const svg = svgRef.current;
    const hnb = headAndBodyRef.current;
    const head = headRef.current;
    const eyelids = eyelidsRef.current;
    const hair = hairRef.current;
    const tl = new TimelineMax();
    const blink = new TimelineMax();
    const hairOptions = {
      yoyo: true,
      ease: Power2.easeInOut,
      transformOrigin: `top center`,
    };

    TweenMax.set(svg, { autoAlpha: 0 });
    TweenMax.set(hnb, { autoAlpha: 0 });
    TweenMax.set(eyelids, { autoAlpha: 0 });

    // Delay for half a second, fade in background circle (1 second)
    // tl.delay(0.5);
    tl.add(TweenMax.to(svg, 0.5, { autoAlpha: 1, ease: Power2.easeOut }));

    // All Head and Body (HNB) animations
    tl.add(
      TweenMax.fromTo(
        hnb,
        1,
        { y: 100, ease: Power2.easeInOut },
        { y: 40, autoAlpha: 1, ease: Power2.easeInOut },
      ),
    );
    tl.add(
      TweenMax.fromTo(hnb, 0.5, { y: 40, ease: Power2.easeInOut }, { y: 90, ease: Power2.easeOut }),
    );
    tl.add(TweenMax.to(hnb, 2, { y: 10, ease: Elastic.easeInOut }));
    tl.add(TweenMax.to(hnb, 0.5, { y: 0, ease: Power2.easeOut }));
    tl.add(TweenMax.to(hnb, 1.25, { y: 0.2, repeat: -1, yoyo: true, ease: Power2.easeInOut }));

    // Head specific animation after HNB movements (separate timeline)
    tl.add(
      TweenMax.to(head, 1.25, { y: 0.25, repeat: -1, yoyo: true, ease: Power2.easeInOut }),
      4.85,
    );

    // Hair animations (separate timeline)
    tl.add(TweenMax.to(hair, 0.3, { scaleY: 1.2, repeat: 1, ...hairOptions }), 1);
    tl.add(TweenMax.to(hair, 0.3, { scaleY: -0.7, repeat: 1, ...hairOptions }), 1.3);
    tl.set(hair, { scaleY: 1 }, 2.8);
    tl.add(TweenMax.to(hair, 0.4, { scaleY: -0.25, repeat: 1, ...hairOptions }), 2.85);
    tl.add(TweenMax.to(hair, 0.4, { scaleY: 1.2, repeat: 1, ...hairOptions }), 3.85);
    tl.add(TweenMax.to(hair, 1.25, { scaleY: 0.95, repeat: -1, ...hairOptions }), 4.9);

    // Blinking (separate timeline)
    blink.add(TweenMax.to(eyelids, 0.1, { delay: 5, autoAlpha: 1 }));
    blink.add(TweenMax.to(eyelids, 0.1, { autoAlpha: 0 }));
    blink.repeat(-1);
    tl.add(blink, 5);

    tl.set(eyelids, { autoAlpha: 1 }, 1.35);
    tl.set(eyelids, { autoAlpha: 0 }, 1.45);
    tl.set(eyelids, { autoAlpha: 1 }, 2);
    tl.set(eyelids, { autoAlpha: 0 }, 3.25);
  }, []);

  return (
    <AvatarImage ref={svgRef} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 80 92.15">
      <defs>
        <clipPath id="mask-avatar">
          <ClearPath d="M11 0v54a39 39 0 0078 0V0z" />
        </clipPath>
        <clipPath id="mask-right-eye">
          <ClearPath d="M37.69 34.58a14.8 14.8 0 006.79-.1 2.92 2.92 0 00-.93-1.16 26.19 26.19 0 00-4.25-.05 4.33 4.33 0 00-1.61 1.3" />
        </clipPath>
        <clipPath id="mask-left-eye">
          <ClearPath d="M57.49 33.29a2.95 2.95 0 00-.92 1.2 15.42 15.42 0 007.04.05c-.6-.53-1.2-1.28-2.09-1.25a39.76 39.76 0 00-4.03 0z" />
        </clipPath>
      </defs>
      <g>
        <OuterCircle cx="40" cy="52.15" r="40" />
        <InnerCircle cx="40" cy="52.15" r="39" />
      </g>
      <g clipPath="url(#mask-avatar)" transform="translate(-10 -1.85)">
        <g ref={headAndBodyRef}>
          <g id="body">
            <ShirtCollar d="M20.26 81.98c1.84-5.11 3.83-4.9 9.81-8.03a35.08 35.08 0 004.49-3c3.28 3.83 13.01 14.76 16.22 14.76s13.77-10.85 16.37-14.8c4.12 3.8 9.57 5.1 12.1 7.09 1.84 1.44 4.82 10.64 6.65 16h.55c-1.52-5.3-4.64-14.43-6.55-16.14-2.68-2.38-5.16-3.13-8.06-4.6a35.06 35.06 0 01-7.07-5.46 3.9 3.9 0 00-2.7-1.39c-7.55-.01-15.1-.03-22.64 0-1.55.08-2.5 1.5-3.6 2.4a39.03 39.03 0 01-10.9 7.01 8.1 8.1 0 00-4.65 4.3A346.2 346.2 0 0015.33 94h.58l4.35-12.02z" />
            <Shirt d="M79.25 78c-2.53-1.99-7.98-3.3-12.1-7.09-2.6 3.95-13.17 14.8-16.37 14.8S37.84 74.78 34.56 70.95a35.08 35.08 0 01-4.49 3c-5.98 3.13-7.97 2.92-9.81 8.03L15.9 94a35 35 0 0069.99 0c-1.83-5.36-4.81-14.56-6.65-16z" />
            <Skin d="M62.72 69.32l-2.54-1.07.02-12.42H41.26l.17 12.5-2.45.99c.29.4 1.71 2.48 2.45 3.29s7.16 7.7 9.41 9.39a100.16 100.16 0 0010.33-10.53c.65-.84 1.02-1.44 1.55-2.14h-.01z" />
            <SkinShadow d="M62.72 69.32c-.84-.37-1.7-.7-2.54-1.07.2-4.25.68-10.61.88-14.85-.97-.83-20.53.39-20.5.7.3 4.07.64 10.16.87 14.24-.83.28-1.64.64-2.45.98a22.78 22.78 0 002.45 3.29c-.28-.92-1-1.7-1.1-2.67.36-.45.82-.82 1.12-1.3.01.18.1 1.4.57 1.5.12-1.97-.38-10.06-.36-12.15a35.54 35.54 0 003.82 3.46 5.2 5.2 0 002.98.68l5.54-.01a3.87 3.87 0 002.92-.94c1.04-1.1 2-2.27 3.03-3.38-.07 4.1-.33 8.2-.41 12.3.58-.09.58-.65.6-1.15a5.04 5.04 0 011.25.96 3.54 3.54 0 01-.22 1.55c.6-.66 1.02-1.43 1.55-2.14z" />
          </g>
          <g ref={headRef}>
            <HatShadow d="M50.44 2.25c1.98.06 8.53-.34 10.75.64s3.82 4.27 5.03 5.81 4.68 5.02 5.52 6.95 2.25 8.1 1.96 9.89-1.88 3.06-2.63 4.06c.22-1.26.38-6.04-1.15-8.42s-8.7-9.4-11.32-10.34-10.13-.6-13.42.44-10 3.92-12.8 8.17-2.08 9.54-2.01 10.86a15.72 15.72 0 01-3-4.11c-.1-2.44 1.1-9.65 1.97-11.37S36.61 4.5 39.94 3.1s8.51-.9 10.5-.85z" />
            <Hat d="M46.29 2.25c2.1-.13 13.17-.55 15.8 1.32s3.76 5.09 5.33 6.68a28.68 28.68 0 014.16 5.23 23.81 23.81 0 011.55 5.71c.3 1.94 1.1 5.04 0 6.15a14.63 14.63 0 00-2.51-7.09c-1.61-2.47-3.33-4.06-7.25-8.12s-9.07-5.25-24.39-.94c1.44-2.62 5.2-8.8 7.3-8.94z" />
            <HatShadow d="M46.62 2.27l1.24-.04c-2.49 2.7-5.2 7.6-5.42 7.67s-.44.02-.46-.27a28.66 28.66 0 014.64-7.36zM48.9 2.2H50c-.7 1.56-2.27 3.67-2.98 5.6-1.2-1.6 1.04-3.13 1.88-5.6zM52.71 2.16c-1.44 2.66-1.12 3.77-2.2 4.36a9.83 9.83 0 011.1-4.36zM54.24 2.2l.98.05c-.94 1.27-.8 2.78-1.74 3.43a11.18 11.18 0 01.76-3.48z" />
            <HatOutline d="M46.02 1.9a14.93 14.93 0 00-7.13 1.33c-2.17 1.03-8.36 9.3-9.55 11.02s-2.5 11.06-2.24 12.19 2.09 2.96 3.04 3.96l.08-.87a10.35 10.35 0 01-2.54-3.33c-.21-.74 1.14-9.73 1.9-11.15s6.82-9.57 9.17-11.03 5.37-1.53 7.22-1.53 7.53.1 9.89-.03a9.7 9.7 0 015.89 1.14c1.96 1.08 2.85 3.73 4.94 6.1a66.72 66.72 0 014.7 5.82c.92 1.3 2.4 9.34 1.93 10.47a8.9 8.9 0 01-2.53 3.09l.05 1.19a11.21 11.21 0 003.02-4.2c.6-1.75-1.04-9.47-1.85-10.95s-4.18-5.07-5.26-6.37-2.54-4.38-4.6-5.65-4.1-1.32-6.35-1.22-6.66.03-9.78.03z" />
            <Skin d="M28.2 32a3.11 3.11 0 012.52 1.35 91.28 91.28 0 001.97 11.48c-2.5-.05-5.64-2.11-6.07-6.79s.27-5.96 1.58-6.05z" />
            <SkinShadow d="M27.43 31.82a2.4 2.4 0 012.98.86l.16 1.27c-1.25-.98-1.23-1.76-2.47-1.6s-1.53 2.23-1.01 6.05 2.86 5.85 5.45 6l.06.76c-2.52-.04-5.71-2.2-6.3-6.72s-.08-6.15 1.13-6.62z" />
            <SkinShadow d="M29.18 36.08c.45-.05-.26 1.33-.42 2.13s.53 2.71.04 2.5-.82-.65-.78-2.6.72-1.98 1.16-2.03z" />
            <Skin d="M72.95 31.97c1.17.02 2.34.6 1.76 5.67s-3.23 7.05-6.34 7.2L69.97 34s1.81-2.05 2.98-2.03z" />
            <SkinShadow d="M70.35 33.4c.36-.91 1.91-2.22 3.18-1.7s1.81 1.17 1.59 5.2-1.87 8.12-6.73 8.28l.14-.86c3.73.02 5.23-3.17 5.69-5.93s.46-6.06-1.15-6.06-1.69 1.13-2.8 1.94z" />
            <SkinShadow d="M72.19 36.06c.98-.1 1 1.08 1.08 2.09s-.62 2.66-.9 2.56.28-1.55.2-2.43-1.36-2.13-.38-2.22z" />
            <HairOutline d="M69.67 20.38a68.1 68.1 0 00-4.9-5.1c-1.2-1.14-2.37-2.32-3.67-3.34a5.9 5.9 0 00-2.38-1.49 24.63 24.63 0 00-10.9-.14 60.7 60.7 0 00-9.35 2.71 16.71 16.71 0 00-3.83 1.94c-1.82 1.3-2.88 3.32-3.97 5.21a39.42 39.42 0 00-2.15 4.37c-.11.33-.35.66-.27 1.02.32.21.59-.15.84-.3a33.27 33.27 0 01.94 4.93 41.89 41.89 0 00.5 5.1c.66 4.07 2.14 10.8 2.36 11.95a7.69 7.69 0 001.29 3.54c1.6 2.15 4.07 3.32 6.03 5.06 1.76 1.36 4.27 3.79 5.6 4.28a13.78 13.78 0 004.6.47c1.59 0 3.33.17 4.64-.39 1.7-.79 6.02-4.64 8.97-6.7a11.9 11.9 0 003.38-3.43c.53-1.09 2.6-11.91 2.76-14.1s.86-5.11 1.17-6.95a43.63 43.63 0 00.22-4.5c.07-1.6-1.02-2.9-1.88-4.14z" />
            <Skin d="M69.6 35.59c-.32 3.88-1.9 12.53-2.78 14.32s-3.1 2.95-5.27 4.68-5.32 4.68-7.15 5.14a36.17 36.17 0 01-7.05.14c-2.26-.32-5.64-3.67-7.13-4.86s-4.91-3.33-5.63-4.68-2.78-8.9-3.1-14.27A25.57 25.57 0 0135 21.04c2.67-4.21 3.97-5.27 7.33-6.7s16.23-2.26 17.8-1.84 3.58 2.42 6.87 6.57 2.93 12.63 2.61 16.52z" />
            <LightBrown d="M65.42 45.24c-10.54 9.48-11.97 11.07-15.09 11.07-3.38 0-7.63-5.13-13.62-10.1a29.33 29.33 0 01-5.36-8.35 85.36 85.36 0 001.67 8.45c1.17 3.03 10.3 12.3 17.34 12.65 8-.46 15.52-7.44 18.01-13.65.32-1.53.65-4.09.91-5.7-.54 2.67-1.89 3.82-3.86 5.63z" />
            <DarkBrown d="M62.05 51.02c-.26-.72-1.35-4.48-2.45-4.95a53.61 53.61 0 00-5.61-2.04 27.9 27.9 0 00-3.78-.1 10.97 10.97 0 00-3.59.26 33.83 33.83 0 00-5.55 2.15c-.71.65-1.55 4.18-1.69 5a21.06 21.06 0 01-7-7.72 55.47 55.47 0 001.75 6.69c.71 1.34 4.22 3.53 5.7 4.73s4.88 4.76 7.14 5.08a40.45 40.45 0 007.46-.16c1.84-.45 5.1-3.37 7.27-5.1s4.52-3.06 5.39-4.85a47.45 47.45 0 001.59-6.74c-1.75 3.64-3.19 5.2-6.63 7.75zm-3.35 3.13a14.95 14.95 0 01-4.6 3.53c-2 .8-6.35.24-7.9-.36s-3.7-2.92-4.14-3.52a5.86 5.86 0 01-.46-3.39c.27-1 .48-2 .78-3 2.02-.59 4.07-1.09 6.12-1.56a12.3 12.3 0 001.92.77 6.02 6.02 0 002.16-.76c2.07.43 4.1.98 6.14 1.54.28.91.52 1.84.74 2.77a4.5 4.5 0 01-.76 3.98z" />
            <HairOutline d="M69.62 35.29l-.02.3c-.32 3.88-1.9 12.53-2.78 14.32s-3.1 2.95-5.27 4.68-5.32 4.68-7.15 5.14a36.17 36.17 0 01-7.05.14c-2.26-.32-5.64-3.67-7.13-4.86s-4.91-3.33-5.63-4.68-2.78-8.9-3.1-14.27l-.01-.77h-.96c.67 4.07 2.15 10.8 2.37 11.95a7.69 7.69 0 001.29 3.54c1.6 2.15 4.07 3.32 6.03 5.06 1.76 1.36 4.27 3.79 5.6 4.28a13.78 13.78 0 004.6.47c1.59 0 3.33.17 4.64-.39 1.7-.79 6.02-4.64 8.97-6.7a11.9 11.9 0 003.38-3.43c.53-1.09 2.6-11.91 2.76-14.1l.06-.68z" />
            <DarkBrown d="M69.9 21.9a21.1 21.1 0 01.7 4.35c-.05.47-.4.78-.55-.02s-.81-3.4-1.02-4.27.56-.82.87-.06zM36.03 16.87c1-.77.9.22.56.65s-2.65 3.6-3.6 4.52-.76.23-.48-.45a21.8 21.8 0 013.52-4.72z" />
            <SkinShadow d="M46.5 41.16c1-.64 2.21 1.44 3.56 1.55 1.5.12 3.13-1.97 4.1-1.24 1.26 1.26-3.54 2.22-4.03 2.2-.67-.02-5.46-1.12-3.62-2.5z" />
            <Nostrils d="M46.34 42.3c-.12-.14.25-.41.58-.36s2.18.94 2.1 1.52a6.38 6.38 0 01-2.68-1.16zM51.05 43.54a3.49 3.49 0 012.19-1.34c.6 0 .68.1.68.31a6.1 6.1 0 01-2.87 1.03z" />
            <SkinHighlight d="M50.86 36.8c.79-.33 1.65 3.2.34 3.52-2.09.5-.78-2.8-.34-3.51z" />
            <SkinShadow d="M45.05 39.93c.19-.28 1.15-1.64 1.34-.75.08.35-.68 1.15-.8 1.45a1.7 1.7 0 00.24 1.88c.35.38 1.15.5 1.22 1.1-2.14.2-2.85-2.13-2-3.68zM54.83 39.27c.71-.5 1.33 1.65 1.3 2.08a2.83 2.83 0 01-2.14 2.42c-.38-.36.97-1.48 1.13-1.86.47-1.1-.3-1.61-.29-2.64z" />
            <White d="M37.69 34.58a14.8 14.8 0 006.79-.1 2.92 2.92 0 00-.93-1.16 26.19 26.19 0 00-4.25-.05 4.33 4.33 0 00-1.61 1.3" />
            <g clipPath="url(#mask-right-eye)">
              <g id="RightEye">
                <Iris d="M41.15 31.37a2 2 0 11-.7 3.43 2.02 2.02 0 01.7-3.43" />
                <EyeColor d="M41.49 35.03a4.98 4.98 0 011.58-.94c-.06.78-.93 1.02-1.58.94z" />
                <White d="M40.7 34c.4-.15.65.47.2.55-.42.18-.68-.49-.2-.55z" />
              </g>
            </g>
            <EyeLashes d="M39.06 33a45.51 45.51 0 015.11-.02c.43.29 1.05.77.92 1.34-.65.21-1.03-.64-1.66-.64-1.14-.02-2.28 0-3.43-.03-.9-.1-1.47.88-2.32.9l-.23-.22c.28-.67 1.02-.99 1.6-1.34z" />
            <EyeBrow
              id="RightEyeBrow"
              d="M36.27 26.86c.64-.28 7.45 1.33 10.79 1.94.61.05.78.87.42 1.29a2.46 2.46 0 01-2.77 1.06c-2.72-1-7.14-2.91-8.11-3.06s-2.32 1.96-3.41 1.75a.6.6 0 01-.12-.4 13.26 13.26 0 013.2-2.58z"
            />
            <SkinShadow d="M37.15 33.14a6.75 6.75 0 011.15-1.39c1.6.1 5.04-.21 6.28.04.02.16-.13.29-.2.37-1.83.15-3.66-.07-5.83.01-.46.21-.83.86-1.4.97z" />
            <White d="M57.49 33.29a2.95 2.95 0 00-.92 1.2 15.42 15.42 0 007.04.05c-.6-.53-1.2-1.28-2.09-1.25a39.76 39.76 0 00-4.03 0z" />
            <g clipPath="url(#mask-left-eye)">
              <g id="LeftEye">
                <Iris d="M59.28 31.38a2 2 0 11-.68 3.42 2.02 2.02 0 01.68-3.42" />
                <EyeColor d="M59.62 35.03a4.56 4.56 0 011.6-.93c-.1.77-.94 1.01-1.6.93z" />
                <White d="M58.85 34c.4-.15.64.48.17.55-.41.18-.66-.5-.17-.55z" />
              </g>
            </g>
            <EyeLashes d="M57.17 32.97c1.46-.07 2.93-.05 4.4-.03.84-.1 1.5.56 2.08 1.07a.31.31 0 01-.34.52c-.73-.26-1.31-1-2.16-.88-1.15.04-2.31-.02-3.46.04-.54.1-1.15 1.1-1.59.44a2.14 2.14 0 011.07-1.16z" />
            <EyeBrow
              id="LeftEyeBrow"
              d="M64.73 26.84a10 10 0 013.26 2.37c.09.28 0 .66-.22.65-.42-.1-1.82-1.57-3.03-1.77s-7.08 2.66-8.3 3.08a1.99 1.99 0 01-2.43-.76c-.3-.55-.92-1.26.75-1.72 3.01-.52 9-2.05 9.97-1.85z"
            />
            <SkinShadow d="M56.8 31.79c1.2.07 4.55 0 6.2.02a3.54 3.54 0 011.07 1.4c-.47-.07-1.01-.79-1.45-1.03-.54-.11-4.23.1-5.73-.02a.31.31 0 01-.1-.37z" />
            <g ref={eyelidsRef}>
              <g id="blinkRight">
                <Skin d="M38.6 30.93c1.03-.33 2.08.2 3.11.29 1.34.32 2.88.21 4 1.1a2.08 2.08 0 01-.17 2.8c-1.45.72-3.12.56-4.69.72a7.27 7.27 0 01-4.24-.76 1.72 1.72 0 01-.44-2.34 5.33 5.33 0 012.43-1.81" />
                <HairOutline d="M44.37 34.16c.37-.07 1.13-.36 1.04.3a15.72 15.72 0 01-6.25.71c-.66-.1-1.5.02-1.96-.56.04-.38.4-.37.7-.3a15.46 15.46 0 006.47-.15z" />
              </g>
              <g id="blinkLeft">
                <Skin d="M56.56 31.93a7.52 7.52 0 014.43-.71c1.32.18 2.64.39 3.97.57a5.81 5.81 0 01-.58 3.45 3 3 0 01-2.3.8 18.27 18.27 0 01-5.93-.73 1.36 1.36 0 01-.99-1.48 2.87 2.87 0 011.4-1.9" />
                <HairOutline d="M56.02 34.3a24.26 24.26 0 003.94.27c1.28 0 2.54-.36 3.81-.3.27.33-.06.6-.38.63a17.35 17.35 0 01-7.04-.02c-.32-.05-.56-.28-.33-.58z" />
              </g>
            </g>
            <g id="Glasses">
              <Glass d="M38.83 39.85c-2.31-.96-2.45-1.21-4.04-3.15.33 1.35.74 3.09 4.04 3.15zM53.4 37c2.12.67.23 2.08 4.7 2.98-2.59.04-3.98-.62-4.7-2.99zM47.45 37.71a3.34 3.34 0 01-2.7 2.18c1.43-.89 1.27-2.32 2.7-2.18zM63.56 39.6c.72-.48 1.34-1.4 2.25-1.49a3.1 3.1 0 01-2.25 1.5z" />
              <Glasses d="M68.95 31.68c-.87-1.07-2.7-1.23-3.96-1.41a27.92 27.92 0 00-4.81-.25 28.4 28.4 0 00-4.64.53c-1.34.26-2.71.52-3.54 1.67a4.37 4.37 0 00-2.84 0c-1.34-1.79-4.48-1.92-6.56-2.1a27.04 27.04 0 00-8.22.47 4.03 4.03 0 00-2.18 1.09c-.43-.08-.93-.32-1.32-.01-.08.83.9.87 1.45 1.13.24.92.6.83 1.43.99.33 2.19.58 4.93 2.83 5.98 2.07.96 4.88.8 7.1.58a5.58 5.58 0 002.9-.82 5.84 5.84 0 001.77-2.84c.28-.85.25-2.44 1.01-2.95a2.38 2.38 0 012.82.32c.33 1.87.73 4.04 2.25 5.36 1.44 1.25 4.02 1.01 5.8 1 1.79-.01 3.74-.02 5.22-1.2 1.5-1.2 1.63-3.68 1.93-5.43 1-.15 1.04-.12 1.44-.99.56-.26 1.55-.3 1.43-1.16a1.87 1.87 0 00-1.31.04zM48.18 35.5a8.24 8.24 0 01-.93 2.5c-.96 1.59-2.3 1.83-4.02 1.97a16.48 16.48 0 01-5.56-.33 3.98 3.98 0 01-2.92-3.02 10.14 10.14 0 01-.42-4.98c1.52-.84 3.5-.87 5.2-.93a23.83 23.83 0 015.69.4c1.39.28 3.37.49 3.35 2.27a11.06 11.06 0 01-.39 2.12zm18.65-.85c-.29 1.58-.56 3.38-1.95 4.37-1.33.95-3.34 1.02-4.92 1.03-1.45 0-3.59.1-4.86-.73-1.29-.83-1.9-2.62-2.17-4.04-.3-1.55-.79-2.99 1.02-3.66a23.7 23.7 0 0110.66-.65c.56.08 1.83.18 2.22.67.47.6.13 2.34 0 3.01z" />
            </g>
            <SkinShadow d="M44.14 47.21c1.7-.25 3.14-.57 3.74-.72s1.88.45 2.55.54 1.57-.61 2.35-.56 3.1.69 4.68.87a.98.98 0 01.74.38l-14.81.06c.08-.2.05-.42.75-.57zM50.45 51.18c3.67 0 3.11-.47 4.56-.37.3.45-.77 1.09-1.96 1.2a18.5 18.5 0 00-4.76.01c-.93.02-2.46-.94-2.02-1.23s.51.4 4.18.4z" />
            <LightBrown d="M47.75 52c1.73.08 2.94-.14 5.65.08-.15.3-.39.87-.85.87-1.43.04-3.01-.02-4.16-.02a1.41 1.41 0 01-.64-.93z" />
            <Lips d="M44.1 47.4c.67-.1 4.34.12 6.58.12 2.33 0 6.34-.1 6.77-.07s.7.1.75.27-.96.72-1.46 1.15-.96 1.1-1.67 1.08c0 0-2.78-.08-4.39-.08s-3.85.28-4.5.04a4.53 4.53 0 01-1.39-1.07c-.4-.34-1.34-.97-1.4-1.06s.06-.29.72-.38z" />
            <White d="M44.32 47.92c.7.46 1.35.9 2 1.42a22.64 22.64 0 002.72.1c1.77-.09 3.65.04 5.43 0 1.26.09 1.62-1.03 2.66-1.5-3.1-.03-7.09-.03-12.8-.02z" />
            <g ref={hairRef}>
              <HairOutline d="M50.54 10.29c4.7-.43 7.25.08 9.54 1.45-1.26 2.09-3.77 2.64-6.07 2.54a6.82 6.82 0 00-5.55 2.06c-2.43 2.63-.35 4.81-1.42 6.81.24-1.7-.55-2.65-.58-4.5s.78-2.57 1.9-3.59a4.55 4.55 0 00-2.7 2.77c-.5 1.58.76 2.89.57 4.89a4.83 4.83 0 01-3.9 3.94A3.77 3.77 0 0138 24.97c-.69-1.41-.13-3.05.73-3.42a3.15 3.15 0 00.4 2.5c.64 1.12 2.81.6 3.38-.49s-.6-2.25-1.34-3.28-.37-4.31.19-5.32a3.73 3.73 0 01-1.39.87c1.2-3.53 5.88-5.12 10.58-5.54z" />
              <DarkBrown d="M54.76 11.57c.25.37-1.9.97-1.94.52-.2-.4 1.86-.9 1.94-.52zM46.95 14.5c.14-.83.8-.96 1.39-1.1.25-.15 1.07.08.6.33a8.59 8.59 0 01-1.99.76zM56.08 12.16c.47-.12 1-.06.79.46-.2.46-1.23.65-1.63.74a11.8 11.8 0 01-4.46.3l-.11-.3c.67-.45 1.75-.3 2.51-.43a25.34 25.34 0 002.9-.77zM42.35 16.68a2.1 2.1 0 011.9-1.41c-.8 1.06-1.53 1.7-1.35 4.5-1.03-.24-.85-2.33-.55-3.09z" />
              <DarkBrown d="M46.04 15.43c-2.1 2.59-1.38 3.54-.66 6.04s-1.59 5.15-4.8 4.35c2.65-.74 4.32-1.7 3.6-4.11s-1.25-4.34 1.86-6.28z" />
            </g>
          </g>
        </g>
      </g>
    </AvatarImage>
  );
};

export default Avatar;
