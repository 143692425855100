import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { TweenMax, TimelineMax, Power2 } from 'gsap';
import { Link, useStaticQuery, graphql } from 'gatsby';
import SEO from '../components/SEO';
import Avatar from '../components/Avatar';
import { portraitOnly, landscapeOnly } from '../styles/mq';
import { textShadow, fg } from '../styles/color';

const Hero = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  margin: 0;
  opacity: 0;
  padding: 0;

  @media (${portraitOnly}) {
    height: 90vh;
  }
  @media (${landscapeOnly}) {
    height: 100vh;
  }
`;

const Header = styled.header`
  display: flex;
  flex-flow: column nowrap;

  @media (${portraitOnly}) {
    align-items: center;
    justify-content: flex-end;
    font-size: 4vw;
  }

  @media (${landscapeOnly}) {
    max-height: 30%;
    font-size: 5vh;
  }
`;

const Title = styled.h1`
  text-align: center;

  @media (${portraitOnly}) {
    margin: 1rem 0 0.5rem;
  }

  @media (${landscapeOnly}) {
    margin: 0.75rem 0 0;
  }
`;

const SubTitle = styled.h2`
  font-weight: 300;
  text-align: center;

  @media (${portraitOnly}) {
    margin: 0 0 1rem;
  }

  @media (${landscapeOnly}) {
    margin: 0 0 0.75rem;
  }
`;

const Main = styled.main`
  display: flex;
  @media (${portraitOnly}) {
    align-items: center;
    flex-flow: column nowrap;
    margin: 0;
  }

  @media (${landscapeOnly}) {
    align-items: center;
    flex-flow: row nowrap;
    justify-content: center;
  }
`;

const AvatarFlex = styled.div`
  @media (${portraitOnly}) {
    width: 40vh;
  }

  @media (${landscapeOnly}) {
    width: 60vh;
  }
`;

const Nav = styled.nav`
  @media (${portraitOnly}) {
  }
  @media (${landscapeOnly}) {
    margin: 0 0 0 1rem;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
  }
`;

const NavLink = styled(Link)`
  color: ${fg};
  display: block;
  font-weight: 700;
  margin: 0;
  padding: 0.25em;
  text-decoration: none;
  text-shadow: ${textShadow};

  @media (${portraitOnly}) {
    text-align: center;
    font-size: 10vw;
  }

  @media (${landscapeOnly}) {
    font-size: 10vh;
    text-align: left;
  }
`;

export default () => {
  const {
    site: {
      siteMetadata: { title, subtitle },
    },
  } = useStaticQuery(graphql`
    query IndexQuery {
      site {
        siteMetadata {
          title
          subtitle
        }
      }
    }
  `);

  const heroRef = useRef();

  useEffect(() => {
    const hero = heroRef.current;
    const tl = new TimelineMax();

    tl.delay(0.5);
    tl.add(TweenMax.to(hero, 0.5, { autoAlpha: 1, ease: Power2.easeOut }));
  }, []);

  return (
    <>
      <SEO />
      <Hero ref={heroRef}>
        <Header>
          <Title>{title}</Title>
          <SubTitle>{subtitle}</SubTitle>
        </Header>
        <Main>
          <AvatarFlex>
            <Avatar />
          </AvatarFlex>
          <Nav>
            <NavLink to="/log/">Log</NavLink>
            <NavLink to="/portfolio/">Portfolio</NavLink>
          </Nav>
        </Main>
      </Hero>
    </>
  );
};
